<template>
  <div class="content-wrapper">
    <h1 class="mb-5">Clusters</h1>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col lg="3">
          <b-form-select
            v-model="companyListSelected"
            :options="filterCompanyList"
            size="sm"
          ></b-form-select>
        </b-col>
        <b-col class="text-lg-right">
          <b-button size="sm" class="mb-2" v-b-toggle.sidebar-roles>
            <b-icon icon="plus" aria-hidden="true"></b-icon> New Cluster
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="items == null" rounded="sm" class="overlay-placeholder">
      <b-card>
        <b-table
          hover
          :items="filteredItems"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
        >
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <b-icon-gear-fill></b-icon-gear-fill>
              </template>
              <b-dropdown-item
                href="#"
                v-b-toggle.sidebar-roles
                @click="
                  currentRole = row.item
                  tmpRole = JSON.parse(JSON.stringify(row.item))
                "
                >Edit</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                v-if="row.item.attiva"
                @click="row.item.attiva = !row.item.attiva"
                >Block</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="
                  currentRole = row.item
                  showDeleteModal = true
                "
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>

          <template #cell(id_company)="row">
            {{ getCompanyName(row.value) }}
          </template>
          <!-- <template #cell(attiva)="row">
            <b-icon-unlock-fill v-if="row.item.attiva"></b-icon-unlock-fill>
            <b-icon-lock-fill v-else></b-icon-lock-fill>
          </template> -->
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="mt-4 mb-1"
        ></b-pagination>
      </b-card>
    </b-overlay>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-roles"
      right
      shadow
      lazy
      @hidden="resetData"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="tmpRole && tmpRole.nome">
          {{ tmpRole.nome }}
        </h4>
        <h4 v-else>New Cluster</h4>
        <b-form-group label="Nome">
          <b-form-input v-model="tmpRole.nome" />
        </b-form-group>
        <b-form-group label="Company">
          <b-form-select
            v-model="tmpRole.id_company"
            :options="companyList"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Permalink">
          <b-form-input v-model="tmpRole.permalink" />
        </b-form-group>

        <div class="mt-5 mb-5">
          <b-button
            squared
            variant="primary"
            size="lg"
            class="big-button"
            @click="saveItem"
            >Confirm</b-button
          >
        </div>
      </div>
    </b-sidebar>
    <b-modal
      v-model="showDeleteModal"
      title="Attention!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="resetData"
      @ok="deleteItem(currentRole.id)"
      >Do you really want to delete the selected element?</b-modal
    >
  </div>
</template>

<script>
import AxiosService from "./../axiosServices/AxiosService.js"
import { theme } from "@/config/global"
import { mapGetters } from "vuex"
export default {
  name: "Clusters",
  components: {},
  data() {
    return {
      items: null,
      fields: [
        { key: "id", label: "ID" },
        { key: "nome", label: "Nome" },
        { key: "id_company", label: "Company" },
        { key: "actions", label: "Actions" },
      ],
      view_lang: null,
      itemsLang: {
        attivita: null,
        mission: null,
        respCompiti: null,
        ruolo: null,
      },
      currentPage: 1,
      perPage: 10,
      filter: "",
      currentRole: null,
      tmpRole: {},
      showDeleteModal: false,

      companyList: [],
      companyListSelected: null,

      theme: "",
    }
  },
  service: null,
  created() {
    this.service = new AxiosService("AdvancedTargeting/GetClusters")

    this.resetData()
    this.theme = theme
    console.log(theme)
  },
  mounted() {
    this.service.read().then((data) => {
      this.items = data
    })
    this.service.getOptions("Company").then((data) => (this.companyList = data))
  },
  methods: {
    resetData() {
      this.currentRole = null
      this.tmpRole = {
        id: 0,
        nome: "",
        id_company: null,
        permalink: "",
      }
      for (const property in this.itemsLang) {
        this.itemsLang[property] = this.default_lang.value
      }
    },
    saveItem() {
      let item = { ...this.tmpRole }
      console.log(item)

      const validationObject = this.$validate("role", item)
      if (!validationObject.validation) {
        this.$errorToast(validationObject.messages.join(", "))
        return false
      }

      if (item.id) {
        this.service
          .putCustomEndpoint("AdvancedTargeting/UpdateCluster", item.id, item)
          .then((response) => {
            if (response.id == item.id) {
              Object.keys(this.currentRole).forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(item, element)) {
                  this.currentRole[element] = item[element]
                }
              })
              this.$root.$emit("bv::toggle::collapse", "sidebar-roles")
              this.$successToast()
            } else {
              this.$errorToast()
              return false
            }
          })
          .catch(() => {
            this.$errorToast()
            return false
          })
      } else {
        this.service
          .postCustomEndpoint(
            "AdvancedTargeting/InsertCluster",
            item.id_company,
            item
          )
          .then((response) => {
            if (response.id) {
              item.id = response.id
              this.items.push(item)
              this.$root.$emit("bv::toggle::collapse", "sidebar-roles")
              this.$successToast()
            } else {
              this.$errorToast()
              return false
            }
          })
          .catch(() => {
            this.$errorToast()
            return false
          })
      }
    },
    deleteItem(id) {
      this.service
        .deleteCustomEndpoint("AdvancedTargeting/DeleteCluster", id)
        .then((response) => {
          if (response) {
            this.items = this.items.filter(
              (currentItem) => id !== currentItem.id
            )
            this.$successToast()
          } else {
            this.$errorToast()
            return false
          }
        })
        .catch(() => {
          this.$errorToast()
          return false
        })
    },

    getCompanyName(id) {
      const company = this.companyList.find((x) => x.value === id)
      return company ? company.text : ""
    },
  },
  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters(["default_lang"]),
    totalRows() {
      return this.filteredItems ? this.filteredItems.length : 0
    },
    filterCompanyList() {
      return [{ value: null, text: "All companies" }, ...this.companyList]
    },

    filteredItems() {
      if (this.items) {
        return this.items.filter((item) => {
          if (
            this.companyListSelected &&
            item.id_company != this.companyListSelected
          ) {
            return false
          }
          return true
        })
      } else {
        return []
      }
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1
      },
    },
  },
}
</script>
